import React from 'react';
import './App.css';
import Header from './components/header'
import Footer from './components/footer'

import Fab from '@material-ui/core/Fab'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

import ScrollTop from './components/scroollToTop'
import colors from './assets/colors'

import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Home from './pages/Home'
import FAQ from './pages/FAQ'
import About from './pages/About'
import ContactUs from './pages/ContactUs'
import NoMatch from './pages/404'

import { createBrowserHistory } from 'history';

function App() {
  return (
    <BrowserRouter history={createBrowserHistory}>
      <div className="App">
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/faq" component={FAQ} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route component={NoMatch} />
        </Switch>
        <ScrollTop>
          <Fab style={{backgroundColor:colors.main, color: colors.white}} size="large" aria-label="scroll back to top">
            <KeyboardArrowUpIcon size="large"/>
          </Fab>
        </ScrollTop>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
