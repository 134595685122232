import React from 'react'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles';

import colors from '../../../../assets/colors'

const promoSectionBodyStyles = makeStyles((theme) => ({
    root: {
        marginTop: '3rem',
        lineHeight: '1.5rem',
    },
    sectionContainer:{
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },    
    },
    section:{
        width: '50%',
        padding: '1rem',
        minHeight: '300px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: '0',
        },    
    },
    sectionTitle:{
        backgroundColor: colors.main,
        padding: '1rem',
        color: colors.white,
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: '2rem',
    },
    sectionCTA : {
        alignSelf: 'flex-end',
        padding: '1rem',
        color: colors.main,
        backgroundColor: colors.white,
        border: `1px solid ${colors.main}`,
        textAlign: 'center',
        fontSize: '1rem',
        width: 'auto',
        transition: 'all .2s ease-in-out',
        '&:hover': {
            color: colors.white,
            backgroundColor: colors.main,
            border: `1px solid ${colors.main}`,
        },
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'unset',
            textAlign: 'right',
            display: 'block',
        },   
    },
    applicant : {

    },
    sponsore : {

    },
}))

export default function PromoBody() {
    const styles = promoSectionBodyStyles()
    return (
        <div className={styles.root}>
            <Container maxWidth="md">
                <p>We are specialists in helping international applicants apply for the Temporary Skill Shortage Visa (subclass 482) as well as assisting Australian employers to become sponsors. If you are interested in applying for a TSS visa as either an applicant or a sponsor, we would be happy to discuss your application with you. We can provide up-to-date information and advice to you that is relevant for your situation and requirements.</p>
                <p>The application requirements and process of the TSS visa can be confusing, but we can prepare and lodge the application for you. This saves considerable time and potential stress because we already know exactly what you need to submit and how to go about it. Australian visa requirements are subject to change, but you can rest assured that we are always up to date with current requirements.</p>
                <p>We have the knowledge and experience to save you time and money by streamlining your Temporary Skill Shortage Visa (subclass 482) application process.</p>
                <div className={styles.sectionContainer}>
                    <div className={`${styles.section} applicant`}>
                        <p className={styles.sectionTitle}>Applicant</p>
                        <p>If you are an applicant, we can help you determine whether you are eligible, whether your employer can become a sponsor, what requirements you will need to meet for the visa, and what the visa conditions are.</p>
                        <button className={styles.sectionCTA}>Contact us</button>
                    </div>
                    <div className={`${styles.section} sponsor`}>
                        <p className={styles.sectionTitle}>Sponsor</p>
                        <p>If you are a sponsor, we can provide information on the details that must be provided, the obligations that are involved, and what is involved in the nomination application.</p>
                        <button className={styles.sectionCTA}>Contact us</button>
                    </div>
                </div>
            </Container>
        </div>
    )
}
