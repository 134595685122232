import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import colors from '../../assets/colors'

const styles = theme => ({
    root:{
        position:'absolute',
        width: '80%',
        left:'0',
        bottom:'15%',
        [theme.breakpoints.up('md')]: {
            display:'none',
        },
        // here should be added media query for down('sm') with landscape
        // menu image should be placed in the right corner
    },
    letter: {
        fill: colors.white,
        opacity: '.4'
    },
    mapBlock: {
        opacity: '.1',
    },
    mapElement: {
        fill: colors.white,
    },
})

class MenuImageBackground extends React.Component {
    render(){
        const {classes} = this.props
        return (
            <div className={classes.root}>
                    <svg x="0px" y="0px" viewBox="0 0 409 364">
                    <g id="map" className={classes.mapBlock}>
                        <g transform="translate(0.000000,1181.000000) scale(0.100000,-0.100000)">
                            <path className={classes.mapElement} d="M2768,11754.5c-20.5-7.2-23.3-19-8.5-35.3l7.6-7.9l-6.3-8.8c-3.6-4.5-8.2-18.1-10.3-29.6
                                c-4.5-23-8.2-31.4-19.3-45c-14.8-17.5-19-30.2-19-55.6c0-21.4,0.9-25.1,9.1-37.2c8.2-11.8,8.5-13.6,3.9-16.3
                                c-18.1-11.2-19.3-13.6-21.1-45.3c-1.2-20.5-3.6-34.4-7.6-43.8c-7.6-16.3-6-36.5,3.6-58.6c6.3-13.9,6.6-18.1,5.1-69.5
                                c-1.2-50.4-2.1-58-12.4-95.8c-5.7-22.7-14.2-51-18.7-63.4c-24.5-68.3-74.6-141.7-96.4-141.7c-16.6,0-76.4,36.2-108.1,65.2
                                c-13.9,13-21.4,17.5-38.4,22.4c-11.8,3.3-30.2,8.8-41.7,12.1c-11.2,3-32.9,13.3-48.6,22.7c-15.4,9.1-29.6,16.6-31.1,16.6
                                s-10.3,7.6-19,16.6c-11.2,11.8-18.1,16.6-23.9,16.6c-9.4,0-9.7,1.2-1.8,11.2c11.2,14.2,5.4,33.5-10,34.4
                                c-16.9,0.9-29.3-2.7-48-14.5c-6.3-3.9-12.7-6.9-14.5-6.9c-1.5,0-16.3,10-32.3,21.7c-16.3,12.1-39.3,27.5-51,34.1l-21.4,12.4
                                l10.3,14.8c5.4,8.2,10,17.2,10,19.9s4.8,11.2,10.6,18.7c12.4,16,16.6,26.3,16.6,38.7c0,5.7,3,11.2,9.1,16.9
                                c14.2,12.4,11.5,29.9-6,37.2c-4.8,2.1-9.1,4.8-9.1,6.3c0,4.8,13.6,11.5,34.4,16.6c29.9,7.6,38.7,16,40.5,39.6
                                c1.2,16.9,2.4,19.3,16,33.8c20.8,22,23.6,43.2,7.2,59.5c-5.4,5.4-10.6,7.2-21.1,7.2c-7.6,0-13.6,1.2-13.6,2.7s-3.6,6.9-7.9,12.1
                                c-5.4,6.6-10.6,9.4-16.9,9.4c-17.5,0-35.6-27.8-35.6-54.1c0-11.5-3.9-12.1-8.5-1.8c-5.1,11.2-4.5,21.1,2.4,34.4
                                c16.9,33.5,5.4,60.7-20.2,47.7c-13.6-7.2-48.3-40.8-55.9-53.8c-6.9-12.4-12.7-15.1-15.7-7.2c-0.9,2.7-5.7,4.5-12.4,4.5
                                c-6,0-13.9,2.1-17.5,4.5c-6,3.9-8.8,3.9-22-0.6c-15.1-4.5-15.7-4.5-24.5,1.8c-4.8,3.9-15.1,7.6-22.4,8.5
                                c-8.5,1.2-16.9,4.8-23.3,10.6c-8.5,7.2-12.4,8.8-22.7,7.9l-12.7-0.9l-3,13.6c-5.7,25.4-24.8,25.1-42.9-0.6
                                c-4.2-6.3-9.1-11.5-10.3-11.5c-1.5,0-4.8,3.3-7.6,7.9c-7.9,11.8-19.6,16.3-43.5,16.3c-20.2,0-22,0.6-29,8.8
                                c-9.1,10.9-33.8,19.9-48.6,17.5c-22.4-3.6-36.2-22-32.3-42.9c3.6-19.9,21.1-28.4,58.9-28.7c15.7,0,20.5-1.2,22-4.8
                                c3.6-9.4,1.8-18.4-4.8-24.8c-10.6-9.7-44.7-24.5-65.5-28.4c-16.6-3-19-3-24.8,2.4c-8.2,7.6-32.3,7.6-51,0.6
                                c-17.2-6.9-63.1-43.5-69.5-55.3c-2.4-4.8-6.9-10.9-9.7-13c-3-2.1-5.4-6.3-5.4-9.1c0-3-2.7-7.6-6-10.6c-8.2-6.9-9.7-41.1-2.4-54.7
                                c4.2-7.6-3.9-13.9-17.2-13.9c-12.7,0-18.4-7.2-21.1-26.3c-1.5-12.1-4.5-18.7-13.3-28.7c-23.6-26.9-24.8-39.6-5.4-58
                                c6.9-6.6,16-14.8,19.6-17.5c4.8-3.9,6.6-8.5,6.6-17.8c0-10.6-0.6-12.1-5.1-10.3c-17.2,7.2-29.6,10.3-53.8,13.3
                                c-44.7,5.4-43.8,5.7-60.7-14.8c-8.2-10-16-18.1-17.2-18.1c-3.6,0-11.2,19.9-11.2,29.6c0,12.7-14.8,33.5-37.2,52.6
                                c-31.7,27.2-55.9,35.6-99.4,35.6c-20.5,0-20.8,0-26.9-10.6c-7.2-11.8-8.5-12.4-46.5-16.3c-22-2.4-27.8-3.9-32.3-9.4
                                c-8.2-10.3-6.9-24.5,3.6-37.8c9.1-11.5,11.8-19.6,6-19.6c-1.5,0-5.7,5.7-9.4,13c-9.4,18.4-14.5,23.3-25.4,23.3
                                c-12.1,0-19.6-7.9-19.6-20.5c0-12.1-6.9-18.7-19.6-18.7c-14.2,0-19.6-6.6-19.6-23.6s5.4-25.7,20.5-33.5l11.2-5.7l-12.1-1.8
                                c-18.4-3-26.9-8.5-28.7-18.4c-1.5-8.5-2.1-8.8-15.4-7.9c-11.8,0.6-15.1-0.3-19-6.3c-2.7-3.6-4.8-10.9-4.8-16s-2.1-12.4-4.8-16
                                c-6.6-9.7-6.6-29,0-36.5c4.2-4.5,4.5-7.3,2.1-12.1c-4.2-7.9-4.2-19.3,0.3-23.9c6.6-6.6-10.9-4.2-25.1,3.3
                                c-21.4,11.5-28.4,12.1-37.8,4.2c-6.9-6-8.5-10.3-9.7-25.1c-1.5-23.6,3-39.6,16-54.7l10.6-12.7l-7.6-11.2c-4.2-6-7.6-12.4-7.6-13.6
                                c0-1.5-1.2-2.7-3-2.7c-1.5,0-4.2,5.4-6,11.8c-1.8,6.6-7.9,19-13.6,27.5c-8.8,13-10.6,18.4-10.6,31.4c0,18.1-1.8,21.4-13.3,26
                                c-10.9,3.9-25.4-0.3-34.4-10c-3.6-3.6-10.3-15.7-15.4-26.3c-4.8-10.9-13.9-26.9-19.9-35.9c-19.9-29-23.9-40.8-23.9-71.3
                                c0-20.8,1.5-31.1,6.6-43.8c3.6-9.1,6.6-20.8,6.6-25.7c0-10.6-7.2-22.7-13.6-22.7c-5.7,0-22-14.5-26.9-23.9
                                c-2.1-4.2-5.1-16.6-6-27.5c-4.2-38.7-15.4-55-67.1-97c-42.6-34.4-51.3-39-96.4-49.5c-43.2-10.3-50.1-13.6-71.9-34.1
                                c-27.2-25.7-31.1-27.8-51.3-27.8c-22,0-34.4-5.1-52.9-22.4c-10.6-10-18.4-14.5-29.6-16.9c-10-2.4-18.4-6.6-25.7-13.9
                                c-7.9-7.9-15.7-11.8-29.6-14.8c-35.9-8.5-47.1-17.8-93.6-77.9c-21.4-28.1-23.9-30.2-47.4-41.4c-41.1-19.3-55.6-36.5-60.1-69.5
                                c-2.7-20.5-10.3-39.9-14.8-38.4c-2.1,0.6-3.6,11.2-4.2,26.3c-0.6,13.9-2.4,27.2-4.2,29.6c-11.5,13.6-35.3-11.2-38.7-39.9
                                c-4.2-40.2,6.6-116.3,21.1-145c9.7-19,9.1-24.2-3.9-32.9c-3.9-2.4-9.1-8.8-11.2-13.9c-3-7.9-3-10.6,1.2-16.9
                                c3-4.8,7.9-7.6,12.7-7.6h7.6l-8.8-11.2c-18.4-23.6-18.4-47.7,0-57.1c13-6.6,34.1-26.9,50.1-47.7c12.7-16.6,39.9-61,39.9-65.5
                                c0-0.9-3.6-1.2-8.2-0.6c-11.2,1.5-22.7-4.8-27.5-15.1c-4.8-10-6.3-9.4-36.2,17.5c-12.1,10.9-24.8,19.6-27.8,19.6
                                c-3.3,0-9.1-3-12.4-6.3c-9.1-9.1-6.6-29,5.1-43.8c4.8-6,13-17.5,17.8-25.4c5.1-8.2,30.2-37.5,55.9-65.2
                                c55.6-59.8,71.3-79.4,74-92.7c1.5-6.9,10.6-17.8,32-38.4c19.9-19.3,31.4-33.2,35.3-42.3c3.3-7.6,9.7-16.9,14.2-21.1
                                c16-14.8,28.4-48.3,33.2-89.1c3.9-34.7,16.9-59.2,59.2-113.3c44.1-56.5,54.1-82.5,58.3-151.9c1.2-16.3,3.9-38.1,6.3-48.3
                                c5.4-23.6,2.4-30.8-17.8-41.7c-8.2-4.5-16.3-10.6-17.8-13.6c-1.8-3-3.6-19.3-4.5-35.9c-1.2-35,2.7-52.3,14.5-58
                                c9.4-4.8,30.5-5.1,40.2-0.6c6.3,3,9.4,1.8,23.6-9.1c27.5-20.8,75.2-32,116.9-26.9c17.2,2.1,21.7,1.5,33.2-3.9
                                c10.6-5.4,16.6-6.3,31.4-4.8c21.4,1.8,31.4,8.2,40.2,25.1c4.2,8.5,9.4,13,18.1,16.3c20.2,7.9,24.8,11.5,42.6,33.5
                                c11.2,14.2,20.8,23,28.1,25.7c21.7,8.8,26.3,12.1,50.1,36.2c26.6,27.2,33.8,31.7,59.8,37.2c10,2.1,23.9,8.2,33.2,14.5
                                c40.2,27.2,63.1,31.4,85.2,15.7c16.6-11.8,31.4-13.6,74.6-9.1c58.6,6.3,71.6,13.9,93.6,55c6,10.9,20.8,32.9,32.9,48.9
                                c23.6,31.4,49.8,53.2,119,98.8c53.2,35,55.3,35.6,122,45.3c87.3,12.7,106.9,19.9,164,59.5c17.8,12.1,38.1,24.2,45.3,26.9
                                c7.2,2.4,33.5,7.9,58.3,11.8c25.1,3.9,56.2,9.4,69.5,12.4l24.2,5.1l40.8-15.4c22.4-8.2,54.4-18.1,71-21.7
                                c16.6-3.3,41.7-11.2,55.9-16.9c38.1-16,46.8-18.1,52.6-13.6c2.7,2.4,4.8,6.6,4.8,10c0,9.1,8.2,1.5,10.6-9.7
                                c2.7-11.8,11.8-23,20.8-25.4c7.9-1.8,10.9-11.2,10.9-33.5c0-7.2,0.9-15.1,1.8-17.8c0.9-2.7,11.5-9.4,23.6-15.1
                                c16.9-8.2,27.2-16.3,46.2-35.9c35.9-37.5,40.2-45,42.9-75.2c2.7-29.3,5.1-34.7,20.2-46.2c23-17.5,56.2-24.8,70.1-14.8
                                c7.9,5.4,9.1,19.6,2.1,25.4c-5.7,4.8-5.7,9.1-0.3,9.1c9.7,0,19.9,13.3,30.8,39.9c9.4,22.7,13.6,29,23.3,35.3
                                c52.6,35.6,56.5,40.8,64,90.9c0.9,6.3,6.3,16,13.3,24.2l11.8,13.6l3.6-8.2c3-6.3,3-9.4,0.3-12.7c-1.8-2.1-5.4-14.5-7.9-27.2
                                c-2.4-13-8.5-29.9-13.6-39.3c-10.6-18.4-16.6-45.3-19.6-85.8l-2.1-28.1l-20.8-10.3c-24.2-12.4-28.1-17.5-22-29
                                c9.4-18.4,42.9-28.4,70.7-20.8c8.2,2.1,17.5,7.9,24.5,14.5c10,10.3,10.9,13,12.7,33.8c2.4,34.4,4.2,36.5,13.9,16
                                c12.1-25.4,13-31.4,7.9-55.3c-2.4-11.8-4.5-29-4.5-37.8c0-14.5,0.9-17.2,7.9-21.7c6.9-4.5,10.3-4.5,29-1.5
                                c11.5,2.1,26.3,6.3,32.6,9.7c14.8,7.6,15.1,7.6,15.1-0.6c0-10,8.8-23,31.4-47.1l19.9-20.8v-46.5c0-55.6,1.2-58.9,33.2-96.4
                                c24.2-28.4,28.1-31.4,49.8-36.5c20.2-4.8,30.8-10.3,46.2-24.5c20.2-18.4,35-21.7,58-12.7c12.4,5.1,12.4,5.1,35.6-5.7
                                c12.7-6,29-11.8,36.2-13s17.2-5.1,22.7-9.1c20.5-15.1,28.1-16.9,67.1-15.7c29.6,0.6,38.1,2.1,46.8,6.9c13,7.6,21.1,23,21.1,39.6
                                c0,15.1,2.1,17.5,10.3,11.8c3.3-2.4,9.1-4.5,12.7-4.5c4.5,0,7.6-2.7,9.7-8.8c6.3-17.8,27.2-18.7,42.9-1.8
                                c7.2,7.6,15.1,10,15.1,4.8c0-1.5-1.2-6.3-3-10.6c-4.2-11.2,0.6-21.1,19-41.7c8.8-9.4,20.5-22.7,26.6-29.3
                                c9.1-10.3,12.7-12.4,22-12.4c13,0,21.1,3.6,32.9,14.8c12.1,10.9,18.1,23.9,16,33.5c-1.5,8.2-0.9,8.5,13.3,9.7
                                c19.6,1.8,25.7,6.3,54.4,40.2c19.3,22.7,26.9,29.3,35.6,31.4c6,1.5,35.6,4.5,65.5,6.3s60.7,4.8,68.6,6.3
                                c26,4.8,38.7,14.5,38.7,29.9c0,3.3,3.6,9.7,8.5,13.9c8.2,7.9,8.2,7.9,8.2,50.7c0,33.8,1.2,44.7,4.8,50.4
                                c7.6,11.5,11.5,25.7,16.6,58.3c6.3,41.1,12.4,53.8,36.2,75.8l19.9,18.4l4.2,26.9c3.3,20.2,8.5,35,20.8,61.9
                                c9.1,19.3,16.6,37.2,16.6,39.6c0,7.9,21.1,48.6,35.3,68.6c10.9,15.4,19.6,23.3,42.9,38.1c34.4,22.4,59.2,48,63.7,66.1
                                c1.8,6.9,7.2,22.4,12.1,33.8c11.2,27.5,16.9,49.8,22.7,89.1c6,41.4,11.8,62.8,21.1,81.6c4.2,8.2,7.6,18.7,7.6,23.6
                                s2.7,12.4,6,16.6c8.8,10.9,14.2,25.1,19.3,49.8c2.4,11.8,10,31.4,16.9,44.7c11.8,22,12.7,25.1,11.2,42c-0.9,10-5.1,30.8-9.7,46.2
                                l-8.2,28.4l6.3,6.6c10.3,10.9,12.7,24.2,9.1,49.8c-3.9,28.1-8.2,37.8-17.2,39c-5.7,0.9-7.9-1.5-13.3-14.2
                                c-12.1-29.3-12.7-20.8-6.6,71.3c1.2,15.7,3.9,29,7.6,36.2c8.5,16.6,7.2,23.6-6.3,38.4c-10,10.9-12.1,15.1-12.1,25.4
                                c0,17.5-3.6,24.8-22.4,45c-9.4,10.3-19,23.3-21.7,29.6c-2.4,6-10.3,17.5-17.5,25.4c-7.2,7.6-14.5,18.4-16.3,24.2
                                c-4.5,13-26.3,38.4-45.9,53.5c-14.5,11.2-15.1,12.1-15.1,26.9c0,20.8-5.1,29.3-19.6,33.5c-16.3,4.2-19.6,12.4-22.7,53.5
                                c-3.3,51.3-17.2,77.6-40.8,77.6c-5.7,0-11.8-2.4-14.8-5.7c-5.4-6-5.4-6-11.8,0c-7.6,6.9-24.5,7.6-35.3,1.2
                                c-10.6-6-11.2-6-19.6,7.2c-5.1,7.2-10,22.4-13.9,41.1c-6.6,32.6-12.7,46.8-19.3,46.8c-2.7,0-6,3.6-8.2,8.5
                                c-10.6,26.3-21.4,45.9-28.1,50.1c-10.9,6.9-9.4,13,4.5,19.9c6.9,3.6,15.1,9.1,18.1,12.1c13.3,13.3,2.7,34.4-19,37.8
                                c-29.3,4.2-42.6,9.1-66.1,23.3c-13.3,8.2-31.4,16.3-39.9,18.1c-19.9,4.5-22,6.3-24.5,20.8c-3,18.4-12.1,26.6-34.4,31.7
                                c-10.6,2.4-26.3,8.2-34.7,12.4c-20.2,10-52.3,32.9-55.9,39.9c-1.8,3-3,14.8-2.7,26.3c0.3,13.3-2.1,28.4-6,42
                                c-3.6,11.8-8.8,37.2-11.2,56.8c-2.7,19.6-6.6,41.7-9.1,48.9c-4.2,14.8-19.3,36.5-35.9,52.6c-6,5.7-10.9,11.5-10.9,12.7
                                c0,0.9,6.3,2.7,14.2,3.6c9.7,0.9,16,3.6,19.6,8.2c6.6,8.5,3.6,15.1-11.2,23.9c-5.7,3.3-10.6,8.2-10.6,10.3c0,2.4-3.9,9.1-8.5,15.1
                                c-4.8,6-11.2,17.2-14.2,25.4c-3,7.9-7.2,16.6-9.7,19.6c-3.6,3.9-3.6,6-0.6,11.2c3.3,4.8,3.3,10.3,0.3,26.6
                                c-7.9,45.6-13,52.9-43.2,62.8c-18.4,6.3-18.7,6.6-22.4,20.5c-4.2,16.6-12.1,26.3-20.8,26.3c-5.4,0-26.6-11.2-41.4-21.7
                                c-5.1-3.3-15.1,3.3-40.8,26.9c-7.6,6.9-10,24.2-4.5,29.6c3,3,4.5,13.6,3.9,24.8c-0.3,3.6-4.2,10.6-8.5,15.7c-7.2,8.8-7.2,9.7-3,16
                                c6.9,9.7,6.3,28.4-1.2,34.7c-3.3,3-6,8.5-6,12.4c0,9.7-7.6,23.3-21.4,38.4l-12.1,13l6.3,10.3c13,21.4,3.6,42-23.9,50.1
                                c-10.6,3.3-12.4,5.1-14.2,14.5c-0.9,5.7-0.9,20.2,0,32.3c2.7,26-1.5,46.8-10.6,54.4c-3.6,2.7-7.6,9.7-8.5,15.1
                                c-1.2,6-4.8,11.2-9.4,13.3C2817,11760.2,2783.1,11759.9,2768,11754.5z"/>
                            <path className={classes.mapElement} d="M1627.8,11628.9c-9.1-1.5-37.8-3.9-63.1-5.1l-46.8-2.7l-9.7-11.2c-11.2-13-17.5-33.2-17.5-55.9
                                c0-13.3,1.2-17.2,6.9-21.7c6.6-5.4,7.9-5.4,14.2-1.2c6.3,3.9,6.9,3.9,8.8-1.5c2.4-7.6,19.6-18.1,29.6-18.1c5.4,0,10.9,3.9,19,13.6
                                c6,7.6,14.5,14.5,19,15.4c31.7,6.9,49.2,20.8,56.2,44.4c4.8,15.7,6.3,47.7,2.4,47.1C1645.3,11631.6,1636.9,11630.4,1627.8,11628.9
                                z"/>
                            <path className={classes.mapElement} d="M2376.6,9096.8c-5.7-2.4-18.1-5.1-27.2-6.3c-8.8-1.2-20.2-4.2-24.8-6.6c-16.9-8.5-22.7-39.6-10.3-53.8
                                c5.1-6,11.2-8.5,26.9-10.9c26.3-4.2,46.8,0,68.9,13.9c9.1,5.7,17.8,10.6,19.6,10.6c2.1,0,5.1,3.3,6.9,7.6
                                C2451.5,9083.8,2415.5,9111,2376.6,9096.8z"/>
                            <path className={classes.mapElement} d="M2990,8531c0.3-29,7.2-47.7,30.8-81.6c9.1-13,22.4-33.8,29.6-46.2c7.2-12.4,16.3-26.3,20.2-30.5
                                c6.6-7.2,6.9-8.2,2.4-18.7c-9.7-23.6-5.1-41.4,16.6-64.3c8.2-8.8,15.7-19.6,16.6-24.2c0.9-4.8,3.9-13,6.3-17.8
                                c2.4-5.1,3.6-11.5,2.7-13.9c-2.7-7.6,6.9-13,29.3-16.9c13.6-2.1,24.2-6,30.8-11.2c5.7-4.2,16.6-9.4,24.5-11.5
                                c17.5-4.8,27.8,0.3,32.9,15.7c2.4,7.3,6.6,12.4,14.5,16.6c12.4,6.6,17.5,16.6,17.5,34.1v10.9l10.3-10c14.2-13.9,22.4-12.1,32,7.2
                                c6.3,13,7.6,19.6,8.2,49.8c0.6,30.8,1.2,34.7,6,35.6c8.5,1.8,18.4,12.4,22.7,24.5c5.4,15.4,4.2,84-1.5,111.5
                                c-8.8,39.9-24.8,59.5-48.6,59.5c-9.1,0-20.2-3.9-39.6-13.9c-32.9-16.9-49.2-21.1-84.9-21.4c-25.1-0.3-29,0.6-52.6,10.9
                                c-28.4,12.7-63.4,21.4-95.1,24.5c-11.5,1.2-23.6,2.7-26.3,3.3C2990.6,8554.6,2990,8552.2,2990,8531z"/>
                        </g>
                    </g>
                    <g id="menu_letters">
                            <path className={classes.letter} d="M48.9,155.3v50.8h-7v-55.4H53l32.3,48.5l32.3-48.5H129v55.4h-6.9v-50l-32.9,50h-7L48.9,155.3z"/>
                            <path className={classes.letter} d="M142.9,206.1v-55.4h55.4v5.8h-48.5V175H196v5.8h-46.2v19.6h48.5v5.8h-55.4V206.1z"/>
                            <path className={classes.letter} d="M214.5,206.1h-6.9v-55.4h12.2l50.8,48.5v-48.5h6.9v55.4h-9.2l-53.7-49.6v49.6H214.5z"/>
                            <path className={classes.letter} d="M355.9,150.7v27.7c0,3.3,0,6.3-0.1,8.9c-0.1,2.7-0.4,5.1-0.8,7.3c-0.5,2.1-1.2,4-2.2,5.6s-2.5,2.9-4.3,3.9
                                c-1.9,1-4.3,1.8-7.2,2.3c-2.9,0.5-6.5,0.8-10.7,0.8h-16.2c-4.2,0-7.8-0.3-10.7-0.8c-2.9-0.5-5.3-1.3-7.2-2.3
                                c-1.9-1-3.3-2.4-4.3-3.9c-1-1.6-1.8-3.5-2.2-5.6c-0.5-2.1-0.7-4.6-0.8-7.3s-0.1-5.7-0.1-8.9v-27.7h6.9v27.7c0,3.5,0,6.4,0.1,8.9
                                s0.3,4.6,0.6,6.3c0.3,1.7,0.9,3.1,1.6,4.1s1.8,1.8,3.2,2.4c1.4,0.6,3.1,0.9,5.2,1.1c2.1,0.2,4.7,0.3,7.8,0.3h16.2
                                c3.1,0,5.6-0.1,7.8-0.3c2.1-0.2,3.9-0.5,5.2-1.1c1.4-0.6,2.4-1.3,3.2-2.4c0.7-1,1.3-2.4,1.6-4.1c0.3-1.7,0.5-3.8,0.6-6.3
                                c0-2.5,0.1-5.5,0.1-8.9v-27.7H355.9z"/>
                    </g>
                    </svg>

                </div>
            )
    }
}
MenuImageBackground.propTypes = {
    classes : PropTypes.object.isRequired
}
    
export default withStyles(styles)(MenuImageBackground)