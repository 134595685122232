import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import colors from '../../../assets/colors'

import backgroundImage from '../../../assets/images/1.jpg'

const sectionStyles = makeStyles((theme) => ({
    root:{
        lineHeight: '1.5rem',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
        },     
    },
    sectionContent: {
        display: 'flex',
        padding: '0 1rem',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    sectionPart: {
        width: '50%',
        order: '2',
        padding: '1.5rem 1.5rem 1.5rem 0',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: '0',
        },
    },
    sectionPartWithImage: {
        position: 'relative',
        backgroundColor: colors.main,
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '450px',
        '&:before': {
            content: '"1"',
            position: 'absolute',
            color: colors.white,
            fontSize: '20rem',
            fontWeight: 'bold',
            top: '50%',
            left: '50%',
            [theme.breakpoints.down('sm')]: {
                left: '40%',
                opacity: .7,
            },
        },
        [theme.breakpoints.down('sm')]: {
            order: '1',
            minHeight: '300px',
        },
    },
    accordionPanel: {
        marginBottom: '1rem',
    },
    accordionPanlContent: {
        flexDirection: 'column',
        
    },
}))

function Step1Section(props) {
    const sectionStyle = sectionStyles()
    return (
        <div className={sectionStyle.root}>
            <Container maxWidth="lg" className={sectionStyle.sectionContent}>
                <div className={sectionStyle.sectionPart}>
                    <p>
                        Step One in the application process for the Temporary Skill Shortage Visa (subclass 482) is
                        the sponsorship stage. This stage is to confirm that the organisation wishing to employ
                        the potential worker is a genuine business. Some of the sponsorship requirements are:
                    </p>
                    <ul>
                        <li>The sponsor must have a legally established and currently operating business.</li>
                        <li>The Australian Government must not be aware of any adverse information about the sponsor, the business or any of its directors that could affect sponsorship suitability.</li>
                        <li>The sponsor must have a commitment to and record of employing local labour and must also declare they will not use discriminatory employment practices.</li>
                    </ul>
                    <p>
                        Once a sponsorship is approved, it is valid for five years from the grant date.
                    </p>
                    <div className={sectionStyle.accordionPanel}>
                        <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <strong>Sponsorship accreditation</strong>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={sectionStyle.accordionPanlContent}>
                                <p>It is recommended that sponsors apply for accredited status as this has
                                    benefits that include priority processing of Temporary Skill Shortage
                                    Visa (subclass 482) nominations and applications.</p>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                    <div className={sectionStyle.accordionPanel}>
                        <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <strong>Sponsorship obligations</strong>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={sectionStyle.accordionPanlContent}>
                                <p>
                                    Strict sponsorship obligations apply to all organisations that sponsor overseas employees on TSS
                                    visas.
                                </p>
                                <p>These obligations include:</p>
                                <ul>
                                    <li>only employing a sponsored person in the occupation that was nominated</li>
                                    <li>paying return travel costs</li>
                                    <li>specific requirements regarding the salary paid to sponsored employees and the nature of the work they carry out</li>
                                    <li>notifying the Department of Home Affairs of certain events within 10 working days (for example, if the person ceases employment or the business stops operating)</li>
                                    <li>maintaining proper records and providing these if requested.</li>
                                </ul>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                    <p>
                        For more details about sponsorship obligations go to: <br/>
                        <a href="https://immi.homeaffairs.gov.au/visas/employing-and-sponsoring-someone/existing-sponsors/standard-business-accredited-obligations" rel="noopener noreferrer nofollow" target="_blank">Sponsorship obligations for Standard business</a>
                    </p>
                </div>
                <div className={`${sectionStyle.sectionPart} ${sectionStyle.sectionPartWithImage}`}>

                </div>
            </Container>
        </div>
    )
}

Step1Section.propTypes = {

}

export default Step1Section

