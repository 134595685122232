import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import colors from '../../../assets/colors'

import backgroundImage from '../../../assets/images/2.jpg'


const sectionStyles = makeStyles((theme) => ({
    root:{
        lineHeight: '1.5rem',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
        },     
    },
    sectionContent: {
        display: 'flex',
        padding: '0 1rem',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    sectionPart: {
        width: '50%',
        order: '2',
        padding: '1.5rem 0 1.5rem 1.5rem',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: '0',
        },
    },
    sectionPartWithImage: {
        position: 'relative',
        backgroundColor: colors.main,
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '300px',
        '&:before': {
            content: '"2"',
            position: 'absolute',
            color: colors.white,
            fontSize: '20rem',
            fontWeight: 'bold',
            top: '50%',
            left: '50%',
            [theme.breakpoints.down('sm')]: {
                left: '30%',
                opacity: .7,
            },
        },
        [theme.breakpoints.down('sm')]: {
            order: '1',
        },
    },
    accordionPanel: {
        marginBottom: '1rem',
    },
    accordionPanlContent: {
        flexDirection: 'column',
        
    },
}))

function Step2Section(props) {
    const sectionStyle = sectionStyles()
    return (
        <div className={sectionStyle.root}>
            <Container maxWidth="lg" className={sectionStyle.sectionContent}>
                <div className={`${sectionStyle.sectionPart} ${sectionStyle.sectionPartWithImage}`}>
                    
                </div>
                <div className={sectionStyle.sectionPart}>
                    <p>
                        Step Two in the application process for the Temporary Skill Shortage Visa (subclass 482) 
                        is the nomination stage, and the purpose of this is to provide details of the job that 
                        required a skilled worker and to confirm that it is a genuine position.
                    </p>
                    <p>
                        The details that must be provided include:
                    </p>
                    <ul>
                        <li>the skills and experience needed for the position</li>
                        <li>the annual market salary rate for the position and the salary rate that will be paid to the prospective overseas employee </li>
                        <li>the name of the prospective overseas employee</li>
                        <li>the location where the employee will be working</li>
                        <li>the occupation relevant to the position to be filled in the relevant stream.</li>
                    </ul>
                    <p>
                    There are three streams relevant to the Temporary Skill Shortage Visa (subclass 482). These are:
                    </p>
                    <div className={sectionStyle.accordionPanel}>
                        <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <strong>The Short-term stream</strong>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={sectionStyle.accordionPanlContent}>
                                <p>IThis stream is available if an employer wishes to bring in a skilled worker to fill 
                                    a position where an appropriate Australian skilled worker cannot be sourced. 
                                    The employer can only sponsor the visa applicant on a temporary basis in 
                                    occupations listed on the Short-term Skilled Occupation list. It can be used 
                                    to fill positions for a maximum of two years; or for four years if an 
                                    International Trade Obligation (ITO) applies.
                                </p>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                    <div className={sectionStyle.accordionPanel}>
                        <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <strong>The Medium-term stream</strong>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={sectionStyle.accordionPanlContent}>
                                <p>
                                    This stream is available if an employer wishes to bring in a skilled worker to fill a position 
                                    where an appropriate Australian skilled worker cannot be sourced. The employer can only sponsor 
                                    the visa applicant on a temporary basis in occupations listed on the Medium and Long-term 
                                    Strategic Skills List (MLTSSL) or the Regional Occupation List (ROL).
                                </p>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                    <div className={sectionStyle.accordionPanel}>
                        <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <strong>The Labour agreement stream</strong>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={sectionStyle.accordionPanlContent}>
                                <p>
                                    This stream is available if an employer wishes to sponsor an overseas worker and has entered into 
                                    a labour agreement with the Department.
                                </p>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                    
                    <p>
                    More information on the requirements of the three streams is available at: <br/>
                        <a href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/temporary-skill-shortage-482" rel="noopener noreferrer nofollow" target="_blank">Temporary Skill Shortage visa</a>
                    </p>
                    <div className={sectionStyle.accordionPanel}>
                        <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <strong>Nomination requirements</strong>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={sectionStyle.accordionPanlContent}>
                                <p>
                                    For a nomination to be approved, the following requirements must be met:
                                </p>
                                <ul>
                                    <li>nomination of an eligible occupation listed in one of the streams</li>
                                    <li>payment of the correct nomination fee</li>
                                    <li>direct employer requirements</li>
                                    <li>mandatory Labour Market Testing (unless exempted)</li>
                                    <li>employment terms and conditions that meet certain requirements</li>
                                    <li>signed employment contract between the parties (the sponsor and the nominee) that meets Fair Work requirements and the relevant State’s provisions.</li>
                                </ul>
                                <p>
                                    The position being nominated must include a salary package that meets the minimum salary as determined 
                                    by the Department of Home Affairs. The salary must also meet average market rates for the role being 
                                    offered. This is to make sure that the overseas worker receives fair payment and that local payment 
                                    rates are not undercut.
                                </p>
                                <p>
                                    In some cases, it may be necessary for the employer to demonstrate that they could not find an 
                                    appropriately skilled Australian worker to fill the position.
                                </p>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                </div>
            </Container>
        </div>
    )
}

Step2Section.propTypes = {

}

export default Step2Section

