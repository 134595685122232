import React from 'react'
import PromoHeader from './header'
import PromoBody from './body'

import { makeStyles } from '@material-ui/core/styles'

const promoSectionStyles = makeStyles(() => ({
    root:{
        marginTop: '3rem',
    },
}))

export default function PromoSection() {
    const styles = promoSectionStyles()
    return (
        <div className={styles.root}> 
            <PromoHeader />
            <PromoBody />
        </div>
    )
}
