import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
    root:{
        margin: '2rem 0'
    },
})

class AccordionItem extends Component {

    render() {
        const {classes} = this.props
        return (
            <div className={classes.root}>
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header"
                    >
                        some text
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        The click event of the nested action will propagate up and expand the panel unless you
                        explicitly stop it.
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        )
    }
}


AccordionItem.propTypes = {
    classes : PropTypes.object.isRequired
}

export default withStyles(styles)(AccordionItem)