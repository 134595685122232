import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import colors from '../../assets/colors'
 
const useStyles = {
    logo: {
      display: 'inline-block',
      lineHeight: '50px',
      fontWeight: '900',
      textTransform: 'uppercase',
      fontSize: '1.5rem',
      color: colors.main,
    },
  }


export class Logo extends Component {
    render() {
        const {classes} = this.props
        return (
            <React.Fragment>
                <div className={classes.logo}>
                    482 TSS Visa
                </div>
            </React.Fragment>
        )
    }
}
Logo.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(useStyles)(Logo)
