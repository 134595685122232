import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import Zoom from '@material-ui/core/Zoom'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(15),
    [theme.breakpoints.down('md')]: {
        right: theme.spacing(5),
    },
    [theme.breakpoints.down('md')]: {
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
  },
}))

export default function ScrollTop(props) {
    const { children, window } = props
    const classes = useStyles()
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
      disableHysteresis: true,
      threshold: 200,
    })

    const handleClick = event => {
        const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor')
        if (anchor) {
          anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      }

    return (
        <Zoom in={trigger}>
          <div onClick={handleClick} role="presentation" className={classes.root}>
            {children}
          </div>
        </Zoom>
    )
}
