import React, { Component } from 'react'

export class NoMatch extends Component {
    render() {
        return (
            <div>
                <h1>404 Page not found</h1>
            </div>
        )
    }
}

export default NoMatch
