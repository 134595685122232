import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import colors from '../../../../assets/colors'

const promoSectionStyles = makeStyles((theme) => ({
    root:{
        display: 'flex',
        width: '100%',
        height:'450px',
        position: 'relative',
        flexDirection: 'row',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        backgroundColor: colors.main,
    },
    title:{
        textAlign: 'center',
        fontSize: '5rem',
        padding: '1rem',
        color: colors.white,
        fontWeight: '900',
        [theme.breakpoints.down('sm')]: {
            fontSize: '4rem'
        },   
    },
    subTitle:{
        textAlign: 'center',
        fontSize: '1.5rem',
        padding: '1rem',
        color: colors.white,
        textTransform:'uppercase',
    },
}))

export default function PromoHeader() {
    const styles = promoSectionStyles()
    return (
        <div className={styles.root}>
            <div className={styles.content} >
                <p className={styles.title}> How we can help? </p>
                <p className={styles.subTitle}>30 sec read only to get the point</p>
            </div>
        </div>
    )
}
