import React, { useState } from 'react'
import Hero from '../../components/hero'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'

import BgHero from '../../assets/images/new-oportunity-in-australia.jpg'

import colors from '../../assets/colors'

const styles = makeStyles( (theme) => ({
    formComtainer: {
        marginTop: '-5rem',
        marginBottom: '3rem',
    },
    formTitle:{
        textAlign: 'center',
        marginBottom: '3rem',
    },
}))

const formStyles = makeStyles( (theme) => ({
    formCTA: {
        padding: '1rem 3rem',
        borderColor: colors.main,
        color: colors.main,
        transition: 'all .2s ease-in-out',
        '&:hover, &:active':{
            backgroundColor: colors.main,
            color: colors.white,
        }
    }
})) 

const Form = (props) => {

    const [ firstName, setFirstName ] = useState('')
    const [ applicantType, setApplicantType] = useState('label')

    const formClasses = formStyles()
   
    return (
        <form noValidate autoComplete="off">
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField id="firstName" value = {firstName} onChange={(e) => setFirstName(e.target.value)} label="First Name" variant="outlined" fullWidth autoComplete="fname" required/>
                </Grid >
                <Grid item xs={12} sm={6}>
                    <TextField id="lastName" label="Last Name" variant="outlined" fullWidth autoComplete="lname" required/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Select
                        id="applicantType"
                        value={applicantType}
                        onChange={ e => setApplicantType(e.target.value) }
                        required
                        variant="outlined"
                        fullWidth
                    >
                        <MenuItem value="label" disabled>Applicant type</MenuItem>
                        <MenuItem value={'applicant'}>Applicant</MenuItem>
                        <MenuItem value={'sponsor'}>Sponsor</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField id="PhoneNumber" label="Phone number" variant="outlined" fullWidth autoComplete="phone" required/>
                </Grid>
                <Grid item xs={12}>
                    <TextField id="comments" label="Comments" variant="outlined" fullWidth multiline={true} rows={4} rowsMax={4}/>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify={'flex-end'}>
                        <Button className={formClasses.formCTA} variant="outlined"> Send request </Button>
                    </Grid>
                </Grid>
            </Grid >
         </form >
    )
}
            
export default function ContactUs () { 
    const pageStyles = styles()

        return (
            <div>
                <Hero 
                    h1Data={`Support at arm's length`}
                    subSectionData={'Contact Us'}
                    bgImage={`${BgHero}`}
                />
                <Container maxWidth="md" className={pageStyles.formComtainer}>
                    <h2 className={pageStyles.formTitle}>
                        Please get in touch with our migration experts by filling in the form below
                    </h2>
                    <Form />
                </Container>
                
            </div>
        )
}