const colors = {
    main: '#009688',
    action: '#E91E63',
    submain: '#009688',
    white: 'white',
    black: 'black',
    grey: '#F8F8F8',
    lightGrey: '#fafafa',
    paleGrey: '#c9c9c9',
    darkGrey: '#D8D8D8',
    orange: '#FF6641',
    yellow: '#ffd45e',
    yellow_opace: 'rgba(255,212,94,.9)'
  };
  
  export default colors;