import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import colors from '../../../assets/colors'

import backgroundImage from '../../../assets/images/3.jpg'


const sectionStyles = makeStyles((theme) => ({
    root:{
        lineHeight: '1.5rem',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
        },     
    },
    sectionContent: {
        display: 'flex',
        padding: '0 1rem',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    sectionPart: {
        width: '50%',
        order: '2',
        padding: '1.5rem 1.5rem 1.5rem 0',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: '0',
        },
    },
    sectionPartWithImage: {
        position: 'relative',
        backgroundColor: colors.main,
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '300px',
        '&:before': {
            content: '"3"',
            position: 'absolute',
            color: colors.white,
            fontSize: '20rem',
            fontWeight: 'bold',
            top: '50%',
            left: '50%',
            [theme.breakpoints.down('sm')]: {
                left: '30%',
                opacity: .7,
            },
        },
        [theme.breakpoints.down('sm')]: {
            order: '1',
        },
    },
    accordionPanel: {
        marginBottom: '1rem',
    },
    accordionPanlContent: {
        flexDirection: 'column',
        
    },
}))

function Step3Section(props) {
    const sectionStyle = sectionStyles()
    return (
        <div className={sectionStyle.root}>
            <Container maxWidth="lg" className={sectionStyle.sectionContent}>
                <div className={sectionStyle.sectionPart}>
                    <p>
                        Step Three in the application process for the Temporary Skill Shortage Visa 
                        (subclass 482) is when the employee who has been nominated in 
                        Step Two lodges their visa application.
                    </p>
                    <p>
                        This application must show that the person applying has the necessary skills, 
                        qualifications and experience to be able to do the nominated position. Skills 
                        assessment may apply in some circumstances.
                    </p>
                    <div className={sectionStyle.accordionPanel}>
                        <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <strong>English language requirements</strong>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={sectionStyle.accordionPanlContent}>
                            <p>
                                The applicant must demonstrate that they have a sufficient level of English for 
                                the job they have been nominated to do.
                            </p>
                            <p>
                                English language tests that can be used are:
                            </p>
                            <ul>
                                <li>International English Language Testing System (IELTS)</li>
                                <li>Occupational English Test (OET)</li>
                                <li>Test of English as a Foreign Language internet-based test (TOEFL iBT)</li>
                                <li>Pearson Test of English Academic (PTE) </li>
                                <li>Cambridge English: Advanced test (CAE), where the test was completed on, or after 1 January 2015.</li>
                            </ul>
                            <p>
                                Minimum scores vary according to the test and the visa stream that applies. 
                                For details, go to:
                                <a href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/temporary-skill-shortage-482/sufficient-english" rel="noopener noreferrer nofollow" target="_blank">English proficiency (subclass 482)</a>
                            </p>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                    <div className={sectionStyle.accordionPanel}>
                        <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <strong>Family members</strong>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={sectionStyle.accordionPanlContent}>
                                <p>
                                    It is permissible for immediate family members to be included as dependant applicants 
                                    on the Temporary Skill Shortage Visa (subclass 482). These family members are usually 
                                    the spouse or de-facto partner and dependent children of the applicant. 
                                </p>
                                <p>More information on applying for a TSS visa go to:  
                                    <a href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/temporary-skill-shortage-482" rel="noopener noreferrer nofollow" target="_blank">Temporary Skill Shortage visa</a>
                                </p>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                </div>
                <div className={`${sectionStyle.sectionPart} ${sectionStyle.sectionPartWithImage}`}>

                </div>
            </Container>
        </div>
    )
}

Step3Section.propTypes = {

}

export default Step3Section

