import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container';
import { FullSupportIcon, FriendlyTeamIcon, ClearAdviceIcon, OnlineIcon } from '../../../assets/icons'
import colors from '../../../assets/colors'
import { makeStyles } from '@material-ui/styles';
import { withStyles } from '@material-ui/core/styles'
//import { wrap } from 'module';

const cardStyles = makeStyles({
    card:{
        padding: '1rem',
        '@media (max-width:960px) and (min-width: 640px)': {
            maxWidth: '200px',
        }
    },
    cardIcon: {
        width: '100px',
        margin: '0 auto',
        height: '100px',
        '@media (max-width:480px)': {
            width: '60px',
            height: '60px',
        }
    },
    cardTitle:{
        color: colors.main,
    },
    cardDescription:{

    },
})

const containerStyles = theme =>({
    cardsContainer:{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: colors.white,
        padding: '2rem',
        textAlign: 'center',
        boxShadow: '0px 10px 15px 5px rgba(0,0,0,.1)',
        [theme.breakpoints.down('sm')]: {
            flexWrap:'wrap',
         },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
         },
    },
})

const Card = ({iconType,title,description}) => {
    const cardClasses = cardStyles()
    const icon = iconType === 'fullSupport' ? <FullSupportIcon /> : iconType === 'online' ? <OnlineIcon /> : iconType === 'clearAdvice' ? <ClearAdviceIcon /> : <FriendlyTeamIcon />
    return(
        <div className={cardClasses.card}>
            <div className={cardClasses.cardIcon}>
                {icon}
            </div>
            <div className={cardClasses.cardTitle}>
                <h3>{title}</h3>
            </div>
            <div className={cardClasses.cardDescription}>
                <p>
                    {description}
                </p>
            </div>
        </div>
    )
}

export class Cards extends Component {
    render() {
        const { classes } = this.props
        return (
            <Container fixed>
                <div className={classes.cardsContainer}>
                    < Card
                        iconType='fullSupport'
                        className={classes.card}
                        title='Full Support'
                        description='We manage your TSS visa application process from beginning to end'
                    />
                    < Card
                        iconType='clearAdvice'
                        className={classes.card}
                        title='Clear Advice'
                        description='Easy to follow checklists setting out everything we need to process your visa'
                    />
                    < Card
                        iconType='online'
                        className={classes.card}
                        title='100% Online'
                        description='Save time and money and send your documents securely to our team'
                    />
                    < Card
                        iconType='friendlyTeam'
                        className={classes.card}
                        title='Friendly Team'
                        description='Our client support team make your experience as stress free as possible'
                    />
                </div>
            </Container>

        )
    }
}

Cards.propTypes = {
    classes : PropTypes.object.isRequired
}

export default withStyles(containerStyles)(Cards)