import React, { Component } from 'react'
import Hero from '../../components/hero'
import Step1Section from './step1Section'
import Step2Section from './step2Section'
import Step3Section from './step3Section'
import PromoSection from './promoSection'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

// images for background

import BgHero from '../../assets/images/482-tss-visa-beaura-migration-agency.jpg'

const styles = theme => ({
    root: {
        marginBottom: '3rem',
    },
    h2Title : {
        marginTop: '-3rem',
        textAlign: 'center',
        padding: ' 3rem 0',
    },
})

export class Home extends Component {
    render() {
        const {classes} = this.props
        return (
            <div className={classes.root}>
                <Hero 
                    h1Data={'Subclass 482 Visa'}
                    h2Data={'Temporary Skills Shortage Visa'}
                    bgImage={BgHero}
                    subSectionData={''}
                />
                <h2 className={classes.h2Title}>Road map for 482 TSS visa applicant</h2>
                <Step1Section />
                <Step2Section />
                <Step3Section />
                <PromoSection />
            </div>
        )
    }
}

Home.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Home)
