import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Logo from '../logo'
import Menu from '../menu'
import Container from '@material-ui/core/Container';

const styles = {
    header:{
        display: 'flex',
        justifyContent: 'space-between'
    },
}

export class Header extends Component {
    render() {
        const { classes } = this.props
        return (
            <React.Fragment>
                <Container fixed id="back-to-top-anchor">
                    <header className={classes.header}>
                        <Link to='/'>
                            <Logo />
                        </Link>
                        <Menu />
                    </header>
                </Container>
            </React.Fragment>
        )
    }
}

Header.propTypes = {
    classes : PropTypes.object.isRequired
}

export default withStyles(styles)(Header)

