import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import { withStyles } from '@material-ui/core/styles'
import Cards from './cards'

import colors from '../../assets/colors'

const containerStyles = theme => ({
    heroContainer:{
        backgroundColor: colors.main,
        backgroundPosition: 'center 80%',
        backgroundSize: 'cover',
        minHeight: '650px',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: '0 1rem',
        },
        [theme.breakpoints.down('xs')]: {
            minHeight: '450px',
            justifyContent: 'start',
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,.3)',
            left: 0,
            top: 0,
        },
    },
    h1: {
        textAlign: 'center',
        width: '100%',
        color: colors.white,
        textShadow: `2px 2px 1px ${colors.main}`,
        fontSize: '4.8rem',
        zIndex:2,
        [theme.breakpoints.down('sm')]: {
            fontSize: '3.2rem',
         },
    },
    h2: {
        textAlign: 'center',
        width: '100%',
        color: colors.white,
        textShadow: `1px 1px 3px ${colors.main}`,
        fontSize: '2.4rem',
        zIndex:2,
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.6rem',
         },
    },
    heroContent: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    subSection:{
        position:'relative',
        top:'-5rem',
        minHeight: '250px',
    },
    subSectionContainer:{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        backgroundColor: colors.white,
        padding: '50px',
        boxShadow: '0px 10px 15px 5px rgba(0,0,0,.1)',
    },
})


export class Hero extends Component {
    render() {
        const { classes } = this.props
        const subSectionContentBlock = <Container maxWidth="md">
                                            <div className={classes.subSectionContainer}><h2>{this.props.subSectionData}</h2></div>
                                        </Container>
        return (
            <div>
                <div 
                    className={classes.heroContainer}
                    style={{ backgroundImage: `url( ${this.props.bgImage} )` }}
                >
                    <h1 className={classes.h1}>{this.props.h1Data || `Hero` }</h1>
                    {this.props.h2Data && <h2 className={classes.h2}>{this.props.h2Data}</h2> }
                </div>
                <div className={classes.subSection}>
                    {this.props.subSectionData ? subSectionContentBlock : <Cards />}                
                </div>
            </div>
        )
    }
}

Hero.propTypes = {
    classes : PropTypes.object.isRequired
}

export default withStyles(containerStyles)(Hero)