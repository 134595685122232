import React, { Component } from 'react'
import Hero from '../../components/hero'
import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'


import BgHero from '../../assets/images/tss482-australia.jpg'

const styles = theme => ({
    root: {
        marginTop: '-5rem',
        marginBottom: '5rem',
        textAlign: 'justify',
        '& > p': {
            marginBottom: '10px',
            lineHeight: '2rem',
        },
    },
    content: {
        
    },
})

export class About extends Component {
    render() {
        const {classes} = this.props
        return (
            <div>
                <Hero 
                    h1Data={'About 482 TSS visa'}
                    subSectionData={'Purpose'}
                    bgImage={`${BgHero}`}
                />
                <Container maxWidth="md" className={classes.root}>
                <p>
                    The Temporary Skill Shortage Visa (subclass 482) allows overseas workers to fill temporary 
                    skill shortages in Australia. Employers are able to bring skilled international workers 
                    into Australia to solve labour shortages if they are unable to source Australian workers 
                    with the right skills for the job. TSS Visa holders can work in Australia for their approved 
                    sponsor in their nominated occupation under one of three streams, which have different 
                    requirements and conditions.
                </p>
                <p>These are:</p>
                <ul>
                    <li>the Short-term stream</li>
                    <li>the Medium-term stream</li>
                    <li>the Labour Agreement stream.</li>
                </ul>
                <p>
                    Contact us for details of the three different streams and to discuss which one will best 
                    suit your needs and circumstances.The temporary Subclass 482 visa allows workers to 
                    accept a job offer from the Australian employer who is sponsoring them. Applicants are 
                    restricted to only working for this employer in the nominated position 
                    (unless the sponsorship is taken over by another employer). 
                    It allows for full-time work for a period of up to four years (depending on three 
                    corresponding stream and other conditions), but it can sometimes be extended for 
                    further time periods.
                </p>
                <p>
                    <strong>Eligibility:</strong> To have basic eligibility for the Temporary Skill Shortage Visa 
                    (subclass 482), the applicant must:
                </p>
                <ul>
                    <li>be nominated for a skilled position by an approved sponsor</li>
                    <li>have the right skills to do the job</li>
                    <li>meet the relevant English language requirements and other conditions that apply.</li>
                </ul>
                <p>
                    <strong>Job skills:</strong> The applicant must be able to show that they have the skills and right 
                    experience and qualifications to be able to do the nominated position. Skills assessment 
                    may sometimes apply.
                </p>
                <p>
                    <strong>English language requirements:</strong> The applicant must have a sufficient level of English to 
                    meet the requirements of the position and the visa stream they are applying for.
                </p>
                <p>
                    <strong>Age:</strong> There is no age requirement for this visa.
                </p>
                <p>
                    <strong>Length of stay:</strong> The length of stay varies according to the applicant’s circumstances, what 
                    the nominated occupation is, and which visa stream is involved. The Temporary Skill Shortage 
                    Visa (subclass 482) can authorise stays in Australia of one to four years.
                </p>
                <p>
                    <strong>Family members:</strong> You can include other family members (who are referred to as ‘secondary 
                    applicants’) in your visa application.
                </p>
                <p>
                    <strong>Health insurance:</strong> TSS visa holders (and their accompanying family members) must have 
                    adequate arrangements for health insurance during their stay in Australia.
                </p>
                <p>
                    <strong>General requirements:</strong> All Temporary Skill Shortage Visa (subclass 482) applicants must 
                    generally be of good health and character.
                </p>
                </Container>
            </div>
        )
    }
}

About.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(About)
