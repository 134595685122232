import React, { Component } from 'react'
import Hero from '../../components/hero'
import AccordionItem from './components/accordion'
import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/styles';

import BgHero from '../../assets/images/australian-visa.jpg'

const styles = theme => ({
    root: {
        
    },
})

const FAQContentStyles = makeStyles(() => ({
    root: {
        marginTop: '-5rem',
    },
}))

const FAQContent = () => {
    const FAQBlockStyles = FAQContentStyles()
    return (
        <div className={FAQBlockStyles.root}>
            <Container maxWidth="md">
                <AccordionItem />
                <AccordionItem />
                <AccordionItem />
                <AccordionItem />
                <AccordionItem />
                <AccordionItem />
                <AccordionItem />
                <AccordionItem />
                <AccordionItem />
            </Container>
        </div>
    )
}

class FAQ extends Component {
    render() {
        const {classes} = this.props
        return (
            <div className={classes.root}>
                <Hero 
                    h1Data={'FAQ about 482 TSS'}
                    subSectionData={'FAQ section'}
                    bgImage={`${BgHero}`}
                />
                < FAQContent />
            </div>
        )
    }
}
FAQ.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(FAQ)